import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { StripeService } from 'ngx-stripe';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private http: HttpClient,
    private stripeService: StripeService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  checkout(priceID, uuid, email): Observable<Object> {
    console.log("Called checkout.");
    return this.http.post('https://us-west1-rxvalidator.cloudfunctions.net/create-checkout-session', { 'lookupKey': priceID, 'userID': uuid, 'email': email });
  }

  public createSession(uuid) {
    console.log("Called createSession.");
    this.http.post('https://us-west1-rxvalidator.cloudfunctions.net/create-portal-session', { 'userID': uuid }).subscribe({
      next: (resp) => {
        this.document.location.href = resp["url"];
      }, 
      error: (err) => {
        console.log("error: " + err);
      }
    });
  }

  getSubscription(uuid) {
    return this.http.get('https://us-west1-rxvalidator.cloudfunctions.net/get-subscription?userID='+uuid)
  }
}
