<!-- Start App Main Banner -->
<div class="main-banner app-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>RxValidator Makes Your Life Easier</h1>
                            <p>Quickly screen for harmful prescription combinations.</p>
                            <p>Contact us: support@rxvalidator.com</p>
                            <button type="submit" class="btn btn-primary" (click)="getStarted()">Get Started</button>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="app-image">
                            <div style="padding:75% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/797807963?h=134b5fca09&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="RxValidator Landing.mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                            <!--<iframe width="560" height="315" src="https://www.youtube.com/embed/PukeF7Mt4GI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            -->
                                <!--
                            <img src="assets/img/app-img4.png" alt="image">
                            <img src="assets/img/app-img2.png" alt="image">
                            -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="creative-shape"><img src="assets/img/bg3.png" alt="bg"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</div>
<!-- End App Main Banner -->


<!-- Start Features Area -->
<!--
<section class="features-area app-features ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>How Can We Help You</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="icofont-camera"></i>
                    </div>
                    <h3>Camera Filter</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="icofont-responsive"></i>
                    </div>
                    <h3>Fast and optimized</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="icofont-hand-drag1"></i>
                    </div>
                    <h3>Drag and Drop</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="features-inner-area">
        <div class="container-fluid">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="assets/img/features-img1.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-ui-call"></i>
                            </div>
                            <h3>Free Caliing Service</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-gift"></i>
                            </div>
                            <h3>Daily Free Gift</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-qr-code"></i>
                            </div>
                            <h3>QR Code Scaner</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
-->
<!-- End Features Area -->

<!-- Start How App Work -->
<section class="how-app-work ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>How It Works</h2>
            <div class="bar"></div>
            <p>Point-and-shoot analysis.</p>
        </div>

        <div class="row">
            <!-- The background image is controlled in src\styles.scss how-app-work url.-->

            <div class="col-lg-4 col-md-6">
                <div class="single-app-works">
                    <div class="icon">
                        <img class="icon" src="https://storage.googleapis.com/static_web_images/phone-app.jpg" alt="mobile">
                        <!--<i class="icofont-downloaded"></i>-->
                    </div>
                    <h3>Photograph</h3>
                    <p>Take a photo of the prescriptions.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-app-works">
                    <div class="icon">
                        
                        <img class="icon" src="https://storage.googleapis.com/static_web_images/rx-document-icon.jpg" alt="mobile">
                    </div>
                    <h3>Analyze</h3>
                    <p>Our system analyzes the prescriptions in the photo.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-app-works">
                    <div class="icon">
                        <img class="icon" src="https://storage.googleapis.com/static_web_images/medical-secure-icon.jpg" alt="mobile">
                    </div>
                    <h3>Done and Secure</h3>
                    <p>Analsyses are deleted after use to ensure privacy protection.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End How App Work -->


<!-- Start Team Area -->
<!--
<section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Our Smart Team</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-member">
                <img src="assets/img/team-img1.jpg" alt="team">

                <div class="team-content">
                    <h3>John Doe</h3>
                    <span>CEO</span>
                </div>
            </div>
        </div>
    </div>
</section>
-->
<!-- End Team Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Easy Pricing Plan</h2>
            <div class="bar"></div>
            <p>A small monthly subscription to support our family service!</p>
        </div>

        <div class="row justify-content-center">
           
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title">Personal</h3>
                        <div class="price"><span class="dollar">$</span>5 CAD<span class="month">/Mo</span></div>
                    </div>

                    <div class="price-body">
                        <ul>
                            <li><b>Unlimited</b> use.</li>
                            <li><b>Cancel</b> anytime.</li>
                            <li><b>Save</b> yourself time on screening thousands of Rx's.</li>
                        </ul>
                    </div>

                    <div class="price-footer">
                        <a class="btn btn-primary" routerLink="/" (click)="getStarted()">Get Started</a>
                    </div>
                </div>
            </div>
            <!--
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title">Business</h3>
                        <div class="price"><span class="dollar">$</span>39<span class="month">/Mo</span></div>
                    </div>

                    <div class="price-body">
                        <ul>
                            <li><b>Free</b> Security Service</li>
                            <li><b>1x</b> Security Service</li>
                            <li><b>Unlimited</b> Security Service</li>
                            <li><b>1x</b> Dashboard Access</li>
                            <li><b>3x</b> Job Listings</li>
                            <li><b>5x</b> invoices and quotes</li>
                        </ul>
                    </div>

                    <div class="price-footer">
                        <a class="btn btn-primary" routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title">Enterprise</h3>
                        <div class="price"><span class="dollar">$</span>99<span class="month">/Mo</span></div>
                    </div>

                    <div class="price-body">
                        <ul>
                            <li><b>Free</b> Security Service</li>
                            <li><b>1x</b> Security Service</li>
                            <li><b>Unlimited</b> Security Service</li>
                            <li><b>1x</b> Dashboard Access</li>
                            <li><b>3x</b> Job Listings</li>
                            <li><b>5x</b> invoices and quotes</li>
                        </ul>
                    </div>

                    <div class="price-footer">
                        <a class="btn btn-primary" routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>
            -->
        </div>
    </div>
    <canvas id="canvas"></canvas>
</section>
<!-- End Pricing Area -->

<!-- Start Feedback Area -->
<!--
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client1.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client2.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>SEO Expert</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client3.jpg" alt="client">
                    <h3>Steven John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client4.jpg" alt="client">
                    <h3>David Warner</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
-->
<!-- End Feedback Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="section-title">
            
            <h2>Disclaimer</h2>
            <div class="bar"></div>
            <p>This service provides "best effort" results using available medical databases and drug sources. Its intended use is as a supplemental tool, not a replacement for due dilligence in Rx analysis. </p>
            <h1>Contact us: support@rxvalidator.com</h1>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->