<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
        <ul class="list">
          <li>
            <a class="" (click)="redirectToDashboard()" class="menu-top">
              <i class="material-icons-two-tone">slideshow</i>
              <span class="hide-menu">Start Rx Analysis
              </span>
            </a>
          </li>
          <li>
            <a class="" (click)="manageSubscription()" class="menu-top">
              <i class="material-icons-two-tone">payments</i>
              <span class="hide-menu">Manage Subscription
              </span>
            </a>
          </li>
          <li>
            <a class="" (click)="logout()" class="menu-top">
              <i class="material-icons-two-tone">power_settings_new</i>
              <span class="hide-menu">Logout
              </span>
            </a>
          </li>
          <li>
            <p class="menu-top">Contact us:</p>
            <p class="menu-top">support@rxvalidator.com</p>
          </li>
        </ul>
      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
