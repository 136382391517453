import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
 
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },
  {
    path: "/dashboard/main",
    title: "Start Rx Analysis",
    iconType: "material-icons-two-tone",
    icon: "slideshow",
    class: "menu-top",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },
  {
    path: "",
    title: "Manage Subscription",
    iconType: "material-icons-two-tone",
    icon: "payments",
    class: "menu-top",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },
  
];
