import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  loginRedirectRoute = "/dashboard/main";
  loginRoute = "/authentication/signin";
  constructor(
    private http: HttpClient,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
    ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.afAuth
    .signInWithEmailAndPassword(username, password)
    .then((result) => {
      this.SetUserData(result.user);
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          this.router.navigate([this.loginRedirectRoute]);
        }
      });
      return "";
    });
  }

  logout() {
    this.afAuth.signOut().then(() => {
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
      this.router.navigate([this.loginRoute]);
    });
    // remove user from local storage to log user out
    
    return of({ success: false });
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('currentUser')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  GoogleAuth(redirectSuccess = this.loginRedirectRoute, redirectFailure = this.loginRoute, withRedirect = true) {
    return this.AuthLogin(new auth.GoogleAuthProvider(), redirectSuccess, redirectFailure, withRedirect);
  }

  TwitterAuth() {
    return this.AuthLogin(new auth.TwitterAuthProvider()).then((res: any) => {
      //this.router.navigate([this.loginRedirectRoute]);
    });
  }

  FacebookAuth() {
    return this.AuthLogin(new auth.FacebookAuthProvider).then((res: any) => {
      //this.router.navigate([this.loginRedirectRoute]);
    });
  }

  // Auth logic to run auth providers
  AuthLogin(provider: any, redirectSuccess = this.loginRedirectRoute, redirectFailure = this.loginRoute, withRedirect = true) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.SetUserData(result.user);
        if(withRedirect) {
          this.router.navigate([redirectSuccess]);
        }
      }).catch((error) => {
        window.alert(error);
        this.router.navigate([redirectFailure]);
      });
  }

  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,

      // TODO Deprecate these
      img: '',
      username: '',
      password: '',
      firstName: '',
      lastName: '',
      token: ''
    };
    localStorage.setItem('currentUser', JSON.stringify(userData));
    this.currentUserSubject.next(userData);
    return userRef.set(userData, {
      merge: true,
    });
  }

  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      });
  }

  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification());
  }
}
